<template>
    <v-menu
            v-model="menu"
            :close-on-click="false"
            :close-on-content-click="false"
            :nudge-width="300"
            min-width="350"
            max-width="350"
            offset-x
    >
        <template v-slot:activator="{ on }">
            <v-btn fab x-small :color="color" depressed :outlined="outlined" dark v-on="on">
                <v-icon v-text="icon"></v-icon>
            </v-btn>
        </template>
        <contact-form v-if="menu" v-bind="contact" v-bind:event-id="eventId" v-bind:autocomplete="autocomplete" @onSave="onSave" @onDelete="onDelete" @onClose="onClose"></contact-form>
    </v-menu>
</template>

<script>

    import contactForm from './ContactListForm'

    export default {
        name: 'sidebar-event-contact-menu-button',
        components: {
            contactForm,
        },
        data() {
            return {
                menu: false,
            }
        },
        props: {
            autocomplete: {
              type: Boolean,
              default: true,
            },
            outlined: {
                type: Boolean,
                default: true,
            },
            eventId: {
                type: Number,
                default: 0,
            },
            icon: {
                type: String,
                default: 'add',
            },
            color: {
                type: String,
                default: 'grey lighten-1',
            },
            contact: {
                type: Object,
                default: () => ({
                    id: null,
                    contactTypeId: 4,
                    contactTypeName: null,
                    deviceId: null,
                    prefix: null,
                    name: null,
                    suffix: null,
                    number: null,
                    code: null,
                    type: null,
                    icon: null,
                    editable: 1,
                    deletable: 0,
                })
            },
        },
        methods: {
            onSave(item, callback) {
                this.$emit('onSave', item, callback)
            },
            onDelete(item, callback) {
                this.$emit('onDelete', item, callback)
            },
            onClose() {
                this.menu = false;
            },
        }
    }
</script>
