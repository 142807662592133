<template>
    <v-card dark :loading="loading" :disabled="loading">
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <div>
                        <v-icon>person</v-icon>
                    </div>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-subtitle>{{ prefix }}</v-list-item-subtitle>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ suffix }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon @click="close()">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-form ref="form" class="pa-3">
            <contact-combobox
                    hide-no-data
                    uppercase
                    outlined
                    prependInnerIcon="person"
                    placeholder="Jméno"
                    v-model="form.name"
                    v-bind:attribute="'name'"
                    class="autoItemHeight mb-1"
                    @choose="setNumber"
                    clearable
                    :autocomplete="autocomplete"
                    :error-messages="nameErrors"
                    :disabled="!canChangeContent"
                    v-bind:autofocus="true"
            >
            </contact-combobox>

            <contact-combobox
                    hide-no-data
                    outlined
                    prependInnerIcon="phone"
                    placeholder="Číslo"
                    v-model="form.number"
                    v-bind:attribute="'number'"
                    class="autoItemHeight mb-1"
                    @choose="setName"
                    clearable
                    :autocomplete="autocomplete"
                    :error-messages="numberErrors"
                    :disabled="!canChangeContent"
            >
            </contact-combobox>
        </v-form>
        <v-card-actions>
            <v-btn text @click="close()">Zavřít</v-btn>
            <div class="flex-grow-1"></div>
            <v-btn color="danger" v-if="canDelete" @click="remove">Smazat</v-btn>
            <v-btn color="success" v-if="canUpdate" @click="save">Uložit</v-btn>
            <v-btn color="success" v-if="canInsert" @click="save">Vytvořit</v-btn>
        </v-card-actions>
        <confirm-dialog ref="confirm"></confirm-dialog>
    </v-card>
</template>

<script>

import contactCombobox from './ContactCombobox'
import {validationMixin} from "vuelidate";
import {required, integer, minLength, maxLength} from "vuelidate/lib/validators";

export default {
    name: 'sidebar-event-contact-form',
    mixins: [validationMixin],
    components: {
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
        contactCombobox
    },
    mounted() {
        this.form = {
            id: this.id,
            eventId: this.eventId,
            contactTypeId: this.contactTypeId,
            name: this.name,
            number: this.number ? this.number.toString() : '',
            editable: this.id ? this.editable : 1,
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            message: false,
            hints: true,
        }
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        eventId: {
            type: Number,
            default: null,
        },
        contactTypeId: {
            type: Number,
            default: 4,
        },
        contactTypeName: {
            type: String,
            default: '',
        },
        deviceCode: {
            type: String,
            default: '',
        },
        deviceId: {
            type: Number,
            default: null,
        },
        prefix: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '&nbsp;',
        },
        suffix: {
            type: String,
            default: '',
        },
        number: {
            type: [Number, String],
            default: '',
        },
        code: String,
        type: String,
        icon: String,
        editable: {
            type: [Number, Boolean],
            default: false,
        },
        deletable: {
            type: [Number, Boolean],
            default: false,
        },
      autocomplete: {
        type: Boolean,
        default: true,
      }
    },
    validations: {
        form: {
            name: {
                required,
            },
            number: {
                required,
                integer,
                minLength: minLength(9),
                maxLength: maxLength(9)
            }
        }
    },
    computed: {
        canChangeContent() {
            return this.editable
        },
        canUpdate() {
            return this.id > 0 && this.canChangeContent;
        },
        canInsert() {
            return this.id === null;
        },
        canDelete() {
            return this.deletable;
        },
        nameErrors() {
            const errors = []
            if (!this.$v.form.name.$dirty) return errors
            !this.$v.form.name.required && errors.push('Prosím vyplňte')
            return errors
        },
        numberErrors() {
            const errors = []
            if (!this.$v.form.number.$dirty) return errors
            !this.$v.form.number.required && errors.push('Prosím vyplňte')
            !this.$v.form.number.integer && errors.push('Toto pole musí obsahovat jen číslice')
            !this.$v.form.number.minLength && errors.push('Vložte devítimístné telefonní číslo')
            !this.$v.form.number.maxLength && errors.push('Vložte devítimístné telefonní číslo')
            return errors
        },
    },
    methods: {

        validate() {
            this.$v.form.$touch();
            if (this.$v.$invalid) {
                this.submitting = false;
                return false;
            }
            return true;
        },
        close() {
            this.$emit('onClose');
        },
        async remove() {
            if (
                await this.$refs.confirm.open(
                    "Potvrzení odebrání",
                    "Chcete opravdu tento kontakt odebrat?"
                )
            ) {
                this.delete();
            }
        },
        delete() {
            this.$emit('onDelete', this.$props, (success) => {
                if (success === true) {
                    this.setLoading(false);
                    this.close();
                } else {
                    this.setLoading(false);
                }
            })
        },
        save() {
            this.loading = true;
            if (!this.validate()) {
                this.loading = false;
                return;
            }

            if (this.form.contactTypeId === undefined)
                this.form.contactTypeId = '';

            this.$emit('onSave', this.form, (success) => {
                if (success === true) {
                    this.setLoading(false);
                    this.close();
                } else {
                    this.setLoading(false);
                }
            })
        },
        setLoading(value) {
            this.loading = value;
        },
        setName(item) {
            this.form.name = item.name;
        },
        setNumber(item) {
            this.form.number = item.number;
        },
    }
}
</script>
