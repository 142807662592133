<template>
    <v-combobox
            :autofocus="autofocus"
            v-model="localModel"
            v-bind="$attrs"
            v-on="inputListeners"
            :loading="loading"
            :items="items"
            hide-no-data
            :item-text="attribute"
            item-value="id"
            auto-select-first
            :search-input.sync="search"
            @keydown="onKeyDown"
            @input="onInput"
            :tabindex="tab"
    >
        <template v-slot:item="{ item }">
            <div class="py-1">
                <strong>{{ item.name }}</strong> - {{ item.number }}<br/>
                {{ item.description }}
            </div>
        </template>
    </v-combobox>
</template>

<script>
    import api from "@/api/api";
    import {toArray} from "lodash"

    export default {
        props: {
            autocomplete: {
              type: Boolean,
              default: true,
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
            uppercase: {
                type: Boolean,
                default: false,
            },
            tab: [ Number],
            value: [String, Number],
            attribute: [String, Number],
        },
        data: () => ({
            cursorStart: null,
            loading: false,
            search: '',
            items: [],
            inputTimeout: null,
        }),
        computed: {
            inputListeners: function () {
                return Object.assign({},
                    this.$listeners,
                    {
                        input: function () {
                        },
                    }
                )
            },
            localModel: {
                get() {
                    return this.value === null ? '' : this.value;
                },
                set(localvalue) {
                    if (localvalue !== null && typeof (localvalue) === 'object') {
                        let value = this.sanitize(localvalue[this.attribute]);
                        this.$emit('input', value);
                        this.$emit('choose', localvalue);
                    } else {
                        this.$emit('input', this.sanitize(localvalue));
                    }
                }
            }
        },
        methods: {
            onInput() {
            },
            onKeyDown(e) {
                this.inputEvent = e;
            },
            makeSearch: async (value, self) => {
              if (!self.autocomplete)
                  return;

                if (!value)
                    self.items = [];

                self.loading = true;
                api.get('/v2/contacts?' + self.attribute + '=' + value + '&fields=id,number,name,description')
                    .then((result) => {
                        self.items = toArray(result);
                    })
                    .catch(() => {

                    })
                    .finally(() => (self.loading = false))
            },
            sanitize(value) {
                if (value === null || value === undefined)
                    return "";

                if (this.uppercase)
                    value = value.toUpperCase();

                return value
            }
        },
        watch: {
            search(value, old) {
                if (old === '' || !value || old === value)
                    return;

                if (value !== null && typeof (value) === 'object') {
                    this.search = this.sanitize(value[this.attribute]);
                } else {
                    this.search = this.sanitize(value);
                }

                this.$emit('input', this.search);

                clearTimeout(this.inputTimeout);
                this.inputTimeout = setTimeout(() => {
                    this.makeSearch(value, this);
                }, 300);
            },
        }
    };
</script>
